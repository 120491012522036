<template>
	<div class="home-page">
		<div class="nodatbox" v-if="list.length == 0">暂无数据</div>
		<div class="bbsddat" v-if="list.length">
			<div class="home-title">
				全部汇总
			</div>
			<div class="num-list">
				<div class="item">
					<div class="icon rightIcon">&#xe696;</div>
					<div class="left">
						<h5>在机款未垫付</h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal1' :endVal='endVal1' decimals=2 :duration='3000' />
						</div>
					</div>
				</div>
				<div class="item">
					<div class="icon rightIcon">&#xe515;</div>
					<div class="left">
						<h5>在机款已垫付</h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal2' :endVal='endVal2' decimals=2 :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<div class="icon rightIcon">&#xe64d;</div>
					<div class="left">
						<h5>在途款</h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal3' :endVal='endVal3' decimals=2 :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<div class="icon rightIcon">&#xe673;</div>
					<div class="left">
						<h5>在库款</h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal4' :endVal='endVal4' decimals=2 :duration='3000' />
						</div>
					</div>

				</div>
				<div class="item">
					<img class="rightIcon" src="../assets/m9.png"></img>
					<div class="left">
						<h5>总金额</h5>
						<div>
							<span>￥</span>
							<countTo :startVal='startVal5' :endVal='endVal5' decimals=2 :duration='3000' />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="list_box">
			<div class="item_box" ref="listItemRef" v-for="(item, index) in list" :key="item.region_name">
				<div class="item">
					<div class="title">
						<p>{{item.region_name}}</p>
					</div>
					<div class="info">
						<div class="left">
							<div class="price_item">
								<div class="icon ">&#xe696;</div>
								<div class="texts">
									<div class="name">在机款未垫付(元)</div>
									<div class="price"><span>￥</span>{{item.online_nopay}}</div>
								</div>
							</div>
							<div class="price_item">
								<div class="icon ">&#xe515;</div>
								<div class="texts">
									<div class="name">在机款已垫付(元)</div>
									<div class="price"><span>￥</span>{{item.online_onpay}}</div>
								</div>
							</div>
						</div>
						<div class="right">
							<div class="price_item">
								<div class="icon ">&#xe64d;</div>
								<div class="texts">
									<div class="name">在途款(元)</div>
									<div class="price"><span>￥</span>{{item.on_passage}}</div>
								</div>
							</div>
							<div class="price_item">
								<div class="icon ">&#xe673;</div>
								<div class="texts">
									<div class="name">在库款(元)</div>
									<div class="price"><span>￥</span>{{item.in_stock}}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="footer_amount">
						总金额：<span>￥</span>{{item.total_amount}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '../http'
	import countTo from 'vue-count-to';
	export default {
		data() {
			return {
				list: [],				
				startVal1: 0,
				endVal1: 0,
				startVal2: 0,
				endVal2: 0,
				startVal3: 0,
				endVal3: 0,
				startVal4: 0,
				endVal4: 0,
				startVal5: 0,
				endVal5: 0,
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.fund.data/',
				data: {}
			}).then((res) => {
				if(res.code==200){
					this.list = res.data.list
					this.endVal1 = parseFloat(res.data.online_nopay)
					this.endVal2 = parseFloat(res.data.online_onpay)
					this.endVal3 = parseFloat(res.data.on_passage)
					this.endVal4 = parseFloat(res.data.in_stock)
					this.endVal5 = parseFloat(res.data.total_amount)
				}
			})
			
		},
		components: {
			countTo
		}
	};
</script>
<style lang="less">.home-title {
	font-size: 18px;
	font-weight: bold;
	color: #333333;
	line-height: 60px;
	justify-content: space-between;
	display: flex;
	align-items: center;

	span {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #757892;
		line-height: 36px;
	}
}

.num-list {
	display: flex;
	flex-wrap: wrap;

	.item {
		width: 20%;
		background-color: #fff;
		height: 100px;
		background: #FFFFFF;
		display: flex;
		margin-bottom: 14px;
		display: flex;
		align-items: center;

		&:nth-child(1) {
			.icon {
				height: 60px;
				width: 60px;
				background-color: #e5eafc;
				font-size: 26px;
				text-align: center;
				line-height: 60px;
				border-radius: 20px;
				color: #747dff;
			}
		}

		&:nth-child(2) {
			.icon {
				height: 60px;
				width: 60px;
				background-color: #e4f2ff;
				font-size: 36px;
				text-align: center;
				line-height: 60px;
				border-radius: 20px;
				color: #3e84ff;
			}
		}

		&:nth-child(3) {
			.icon {
				height: 60px;
				width: 60px;
				background-color: #fff3ef;
				font-size: 36px;
				text-align: center;
				line-height: 60px;
				border-radius: 20px;
				color: #fc9d4e;
			}
		}

		&:nth-child(4) {
			.icon {
				height: 60px;
				width: 60px;
				background-color: #e3f8e8;
				font-size: 32px;
				text-align: center;
				line-height: 60px;
				border-radius: 20px;
				color: #24bb5f;
			}
		}

		&:nth-child(4n) {
			margin-right: 0px;
		}

		&:nth-child(5) {
			margin-bottom: 0px;
		}

		&:nth-child(6) {
			margin-bottom: 0px;
		}

		&:nth-child(7) {
			margin-bottom: 0px;
		}

		&:nth-child(8) {
			margin-bottom: 0px;
		}

		.left {
			display: flex;
			height: 100px;
			box-sizing: border-box;
			flex-direction: column;
			justify-content: center;
			padding-top:0;

			h5 {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #757892;
				margin-bottom: 14px;
				line-height: 18px;
			}

			&>div {
				font-size: 20px;
				font-family: Alibaba PuHuiTi;
				font-weight: 400;
				color: #262B4F;
				font-weight: 700;
				line-height: 24px;

				&>span:nth-child(2) {
					margin-left: -8px;
				}
			}
		}

		.rightIcon {
			width: 60px;
			height: 60px;
			border-radius: 20px;
			margin-right: 23px;
			margin-left: 28px;
		}
	}
}

.bbsddat {
	background: #FFFFFF;
	border-radius: 10px;
	padding: 0 23px;
	margin-bottom: 20px;
}
	.nodatbox {
		line-height: 300px;
		text-align: center;
		font-size: 16px;
		opacity: 0.8;
		text-align: center;
	}
	.list_box {
		display: flex;
		flex-wrap: wrap;
	}
	
	.icon {
		font-family: "DINALTERNATE";
	}
	
	.item_box {
		width: 32.6666666%;
		margin-right: 1%;
		&.active_0 {
			transform: translateY(50px);
			opacity: 0.5;
			transition: all 0.6s cubic-bezier(0.51, -0.32, 0.47, 0.56);
		}
	
		&.active_1 {
			transform: translateY(100px);
			opacity: 0.5;
			transition: all 0.8s cubic-bezier(0.51, -0.32, 0.47, 0.56);
		}
	
		&.active_2 {
			transform: translateY(150px);
			opacity: 0.5;
			transition: all 1s cubic-bezier(0.51, -0.32, 0.47, 0.56);
		}
	
		&:nth-child(3n) {
			margin-right: 0px;
		}
	
		&.active {
			&.active_0 {
				opacity: 1;
				transform: translateY(0px);
			}
	
			&.active_1 {
				opacity: 1;
				transform: translateY(0px);
			}
	
			&.active_2 {
				opacity: 1;
				transform: translateY(0px);
			}
		}
	}
	
	.item {
		border-radius: 10px;
		width: 100%;
		background-color: #fff;
		background-size: cover;
		margin-bottom: 14px;
	
		.title {
			width: 100%;
			height: 60px;
			padding-left: 26px;
			display: flex;
			align-items: center;
			margin-bottom: 0px;
	
			p {
				font-size: 18px;
				font-weight: bold;
				color: #031935;
				line-height: 60px;
				margin-bottom: 0px;
			}
		}
		
		.footer_amount {
			height: 60px;
			font-size: 18px;
			font-weight: bold;
			color: #031935;
			line-height: 60px;
			text-align: right;
			padding-right: 14px;
		}
	
		.info {
			padding-left: 14px;
			display: flex;
	
			.left {
				border-radius: 10px;
				height: 274px;
				background-color: #f8f8f8;
				margin-right: 10px;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				padding-left: 14px;
				padding-right: 14px;
				width: 48%;
				.price_item {
					display: flex;
					height: 137px;
					align-items: center;
					&:nth-child(1) {
						 border-bottom: 1px solid #f0f0f0;
					}
					img {
						width: 50px;
						height: 50px;
						margin-right: 4px;
					}
					
					&:nth-child(1) {
						.icon {
							height: 60px;
							width: 60px;
							background-color: #e5eafc;
							margin-right: 6px;
							font-size: 26px;
							text-align: center;
							line-height: 60px;
							border-radius: 20px;
							color: #747dff;
						}
					}
					
					&:nth-child(2) {
						.icon {
							height: 60px;
							width: 60px;
							background-color: #e4f2ff;
							margin-right: 6px;
							font-size: 36px;
							text-align: center;
							line-height: 60px;
							border-radius: 20px;
							color: #3e84ff;
						}
					}
	
					.texts {
						display: flex;
						align-items: flex-start;
						flex-direction: column;
						justify-content: center;
	
						.name {
							font-weight: 500;
							font-size: 20px;
							line-height: 22px;
							color: #757892;
							margin-bottom: 11px;
							color: #757892;
							line-height: 18px;
							font-size: 16px;
							font-weight: 400;
							color: #757892;
							margin-bottom: 14px;
						}
	
						.price {
							font-weight: 400;
							font-size: 20px;
							line-height: 20px;
							color: #262b4f;
							font-family: Alibaba PuHuiTi;
							position: relative;
							left: 0px;
							color: #262B4F;
							font-weight: 700;
							line-height: 24px;
						}
					}
				}
			}
	
			.right {
				width: 48%;
				.price_item {
					background-color: #f8f8f8;
					display: flex;
					align-content: center;
					width: 100%;
					height: 133px;
					padding-left: 14px;
					display: flex;
					align-items: center;
					border-radius: 10px;
					
					&:nth-child(1) {
						margin-bottom: 8px;
					}
	
					&:nth-child(1) {
						.icon {
							height: 60px;
							width: 60px;
							background-color: #fff3ef;
							margin-right: 6px;
							font-size: 36px;
							text-align: center;
							line-height: 60px;
							border-radius: 20px;
							color: #fc9d4e;
						}
					}
					
					&:nth-child(2) {
						.icon {
							height: 60px;
							width: 60px;
							background-color: #e3f8e8;
							margin-right: 6px;
							font-size: 32px;
							text-align: center;
							line-height: 60px;
							border-radius: 20px;
							color: #24bb5f;
						}
					}
	
					.texts {
						.name {
							font-weight: 500;
							font-size: 20px;
							color: #757892;
							line-height: 22px;
							margin-bottom: 5px;
							color: #757892;
							line-height: 18px;
							font-size: 16px;
							font-weight: 400;
							color: #757892;
							margin-bottom: 14px;
						}
	
						.price {
							font-weight: 400;
							font-size: 20px;
							color: #262b4f;
							font-family: Alibaba PuHuiTi;
							line-height: 20px;
							color: #262B4F;
							font-weight: 700;
							line-height: 24px;
							position: relative;
						}
					}
				}
			}
		}
	}
</style>